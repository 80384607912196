.back_color{
  margin-bottom: 20px;
  margin-top: 0;
}
.seiyakuZumi_color{
  background-color: whitesmoke;
  opacity: 0.5;
}
.label-seiyakuZumi2 {
  border-color: red;
  background-color: red;
  height: 30px;
  width: 150px;
  font-size: 18px;
  padding-top: 6px;
}
.text_style{
  font-family:"Noto Sans JP",sans-serif;
  font-weight: bold;
  text-align: left;
  margin: 20px 20px 0 20px;
}
.title_border{
  /* border-bottom: solid 1px lightgray; */
  margin-bottom: 20px;
}
.cost_style{
  font-weight: normal;
}
.deposit_style{
  font-weight: normal;
}

.detailtop{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.favdown{
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-left: auto; */
}

.favdown .FavBtn{
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  height: 55px;
  width: 105px;
}

/* .favdown .DownBtn{
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  height: 55px;
  width: 105px;
} */

.favdown .DownBtn{
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  padding-top: 0;
  padding-bottom: 0;
  height: 55px;
  width: 105px;
  text-decoration: none;
  background-color: #fff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.favdown .DownBtn:hover{
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.favdown .DownBtn p{
  text-align: center;
}

@media screen and (max-width: 768px) {
  .detailtop{
    display: block;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .detailtop h3{ 
    font-size: 23px;
  }
  .favdown{
    justify-content: right;
    gap: 5px;
  }
  .favdown .FavBtn{
    height: 55px;
    width: 100px;
    padding: 5px 10px;
  }
  
  .favdown .DownBtn{
    height: 55px;
    width: 100px;
  }
}

.cost_style{
  display: flex;
}
.cost_style dl{
  display: flex;
  align-items: center;
  width: auto;
  margin: 0;
}
.cont_title{
  background-color: #707070;
  padding: 2px 5px;
  color: #fff;
  font-size: 15px;
  height: 28px;
}

.cost_style .nums{
  padding: 2px 30px 2px 10px;
  font-size: 17px;
  margin: 0;
}
.cost_style .nedan{
  color: #C1272D;
  font-size: 22px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .cost_style{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .cost_style dl{
    display: flex;
    align-items: center;
    width: auto;
    margin: 0;
  }
  .cont_title{
    padding: 2px 5px;
    font-size: 12px;
    height: 24px;
  }
  
  .cost_style .nums{
    padding: 2px 20px 2px 10px;
    font-size: 15px;
    margin: 0;
  }
  .cost_style .nedan{
    color: #C1272D;
    font-size: 20px;
    font-weight: bold;
  }
}