.table_style {
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    width: 100%;
    overflow-x: scroll;
}

.table_style table{
    width: 100%;
    /* tdとかが大きく表示される */
    width:max-content;
}
/* @media screen and (max-width: 1100px) {
    .table_style {
        overflow-x: scroll;
    }
} */
.table_style td, .table_style th{
    padding: 0.3em;
    position: relative;
    width: auto;
    height: auto;
}
.table_style table .s{
    max-width: 120px;
}
.seiyakuZumiStyle{
    background-color: lightgray ;
    opacity: 0.5;
}
.label-seiyakuZumi {
    border-color: grey;
    background-color: gray;
}
.maru {
    height:20px;
    width:20px;
    border-radius:50%;
    line-height:20px;
    text-align:center;
    color: white;
    font-size:15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
  }

  td .kyori_num{
    color: #BF4645;
    font-size: 17px;
  }