
.for-large{
    display: block;
}
.for-small{
    display: none;
}

@media screen and (max-width: 768px) {
    .for-large{
        display: none;
    }
    .for-small{
        display: block;
    }
}

#shopbox {
    margin-top: 10px;
    font-family:"Noto Sans JP",sans-serif;
    background-color: #fff;
    padding: 50px;
}
#shopbox h3{
    font-size: 20px;
    border-left: 2px solid #4AC0F0;
    padding-left: 10px;
}

.shopbox {
    display: flex;
    padding: 20px 0;
    gap: 40px;
}
.imgbox, .detail{
    flex-basis: calc(50% - 20px);
}

.shopbox .imgbox img{
    width: 100%;
}

.shopbox .imgbox .staff{
    padding-top: 20px;
}

.shopbox .detail .tenpo_name{
    font-size: 20px;
    text-align: center;
    margin: 30px 0 50px;
}   

.tenpo dl{
    display: flex;
    font-size: 15px;
}

.tenpo dl dt{
    width: 100px;
    display: flex;
    text-align:justify;
    text-align-last:justify;
    color: #4AC0F0;
    letter-spacing: 1px;
}

.tenpo dl dd{
    width: calc(100% - 100px);
    text-align: left;
}

.shopmap{
    padding-top: 20px;
    height: 420px;
}
@media screen and (max-width: 768px) {
    #shopbox {
        margin-top: 10px;
        padding: 20px;
    }
    #shopbox h3{
        font-size: 18px;
        padding-left: 10px;
    }
    .shopbox {
        display: flex;
        flex-flow: column-reverse;
        padding: 0;
        gap: 20px;
    }
    .imgbox, .detail{
        flex-basis: 100%;
    }
    
    .shopbox .detail .tenpo_name{
        font-size: 18px;
        text-align: center;
        margin: 20px 0 40px;
    }   
    
    .tenpo dl{
        display: flex;
        font-size: 14px;
    }
    
    .tenpo dl dt{
        width: 90px;
    }
    
    .tenpo dl dd{
        margin-bottom: 0;
        width: calc(100% - 90px);
        text-align: left;
    }
    
    .shopmap{
        padding: 20px 0;
        height: 240px;
    }
}
