.main_background{
  background-color: whitesmoke;
}
.component_style{
  width: 80%;
  padding-top: 20px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70px;
}
.btn_style_vr {
  font-weight: bold;
  font-size: large;
  color: white;
}
@media screen and (max-width: 960px) {
  .component_style{
    width: 90%;
  }
  .btn_style_vr {
    font-size: unset;
  }
}