.display_style{
    background-color: whitesmoke;
    padding-top: 70px;
    padding-bottom: 30px;
}
.frame{
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #434a54;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}
@media screen and (max-width: 960px) {
}


.credit_hdr{
  background: #008c6e;
  color: #ffffff;
  margin-bottom: 30px;
  padding: 15px;
  text-align: center;
}
.display_style{
  padding-top: 70px;
  padding-bottom: 30px;
}
.frame{
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #434a54;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}
.confirm_h1{
  font-size: 19px;
  color: #008c6e;
  border: solid 3px #008c6e;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
.btn_style{
  font-size: 14px;
  color: #ffffff;
  background-color: #008c6e;
  border-color: #008c6e;
  border: 1px solid #008c6e;
  border-radius: 4px;
  width: 400px;
  height: 60px;
  letter-spacing: 5px;
  margin-top: 5px;
  padding-top: 5px;
}
.login_btn{
  width: 300px;
}
@media screen and (max-width: 480px) {
  .btn_style{
    width: 200px;
  }
  .login_btn{
    width: 200px;
  }
}




table {
    border: 1px solid #dcdcdc;
    width: 100%;
}

th {
    background-color: #e8e8e8;
    border: 1px solid #cccccc;
    height: 40px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: normal;
}

td {
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: left;
    padding-left: 20px;
}

.form-group {
    margin-bottom: 0px;
}

.disp_table th{
    height:28px;
    font-size: 13px;
}
.disp_table td{
    height:28px;
}