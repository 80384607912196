#proposing-items-navi {
    background-color: #FFFFFF;
}

#proposing-items-navi .nav-tabs li {
    width : 33.33333% ; /* 未対応ブラウザ用フォールバック */
    width : -webkit-calc(100% / 3) ;
    width : calc(100% / 3) ;
    text-align: center;    
    border-top: #CCD1D9 4px solid;
    border-right: #CCD1D9 1px solid; 
    padding-top: 10px;
    padding-bottom:10px;
}
#proposing-items-navi .nav-tabs li.active{
    border-top: #3BAFDA 4px solid
}
#proposing-items-navi .nav-tabs li a {
    padding-left: 0;
    padding-right: 0;
    background-color: white;
    color: #555555
}
#proposing-items-navi .nav-tabs li a i{
    margin-right: 5px;
}