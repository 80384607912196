input[type="checkbox"] {
    transform: scale(1.5);
}

label {
    display:block;
    position:relative;
}

.conditions_style{
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    margin-bottom: 100px;
}
.conditions_style_application{
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    margin-bottom: 30px;
}
.container h6{
    font-weight: bold;
}
.display_style{
    background-color: whitesmoke;
    padding-top: 50px;
    padding-bottom: 70px;
}
.title_style{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
}
.msg_font{
    font-weight: bold;
}
.border_style{
    border: solid 1px lightgray;
    border-radius: 0.5em;
}
/* .monthly-h1 {
    font-size: 19px;
    border: solid 2px;
    padding: 0.5em;
    border-radius: 0.5em;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
} */

/* .pg-hdr {
    background: url(../Images/bg-slash-green.png) left top repeat;
    color: #ffffff;
    padding: 15px;
    text-align: center;
} */
#footer-submit_button{
    /* position: fixed; */
    height: auto;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 60px;
    left: 0;
    width: 100%;
    /* border-top: 1px solid #c8c7cc; */
    background-color: white;
    background-color: rgba(255,255,255,0.9);
    z-index: 900;
}
.MuiIconButton-label{
    background-color: white;
}
.station_style{
    writing-mode: vertical-rl;
    width: 50px;
    height: 173px;
}
.privacyPolicy_text {
    width: 80%;
    height: 150px;
    margin: auto;
    overflow: auto;
    border: black 1px solid;
}
.privacyPolicy_title, .privacyPolicy_agree {
    margin-left: 120px;
}
.privacyPolicy_text p{
    font-size: 12px;
}

@media screen and (max-width: 960px) {
    .conditions_style{
        width: 100%;
    }
    .msg_font{
        font-size: 12px;
    }
    .pd_l{
        padding-left: 30px;
    }
    .privacyPolicy_text {
        width: 90%;
    }
    .privacyPolicy_title, .privacyPolicy_agree {
        margin-left: 20px;
    }
}