#footer-menublock {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background-color: white;
    background-color: rgba(255,255,255,0.9); */
    background-color: rgba(0,0,0,0.5);
    z-index: 900;
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer_btns{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

#TelBtn{
    display: none;
}
@media screen and (max-width: 768px) {
    
    .footer_btns {
        gap:5px;
    }
    #TelBtn{
        display: block;
        padding: 5px;
        background-color: #fff;
        border-radius: 0; 
        width: 45px;
        height: 45px;
        text-align: center;
        color: rgb(128, 128, 128);
    }
    #TelBtn .MuiSvgIcon-root{
        font-size: 25px;
    }
    #TelBtn svg{
        margin-bottom: 0px;
        margin-top: 5px;
        color: rgb(128, 128, 128);
        
    }
}

#LineBtn{
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
}
#LineBtn .css-htszrh-MuiButton-startIcon svg{
    overflow: hidden;
    vertical-align: super;
}
#LineBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 30px;
}
#RaitenBtn{
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
}
#RaitenBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 32px;
}

#MailBtn{
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
}
#MailBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 32px;
}
#MailBtn:hover{
    color: #fff;
}
@media screen and (max-width: 768px) {
    #LineBtn{
        width: calc(50% - 45px);
        border-radius: 0;
        font-size: 14px;
        line-height: 1.2;
        height: 45px;
    }
    #LineBtn .css-htszrh-MuiButton-startIcon svg{
        overflow: hidden;
        vertical-align: super;
    }
    #LineBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
        font-size: 25px;
    }
    #RaitenBtn{
        width: calc(50% - 45px);
        text-align: left;
        border-radius: 0;
        font-size: 14px;
        line-height: 1.2;
        height: 45px;
    }
    #RaitenBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
        font-size: 27px;
    }
    #MailBtn{
        display: none;
    }
}
/* #footer-menublock ul.footer-menu_list {
    height: 45px;
    border-top: 1px solid #c8c7cc;
    display: flex;
}
.pa-left-0 {
    padding-left: 0px !important;
}
ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
}
#footer-menublock ul.footer-menu_list li {
    flex: auto;
    float: left;
    list-style: none;
    position: relative;
    text-align: center;
    padding-top: 5px;
}
#footer-menublock ul.footer-menu_list li a.current {
    color: #3BAFDA;
}
#footer-menublock ul.footer-menu_list li a {
    position: relative;
    display: inline-block;
    color: #979797;
}
#footer-menublock ul.footer-menu_list li i {
    display: block;
    font-size: 30px;
}
#footer-menublock a:hover{
    text-decoration: none;
} */