#footer-menublock {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* background-color: white;
    background-color: rgba(255,255,255,0.9); */
    background-color: rgba(0,0,0,0.5);
    z-index: 900;
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer_btns{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

#TelBtn{
    display: none;
}
@media screen and (max-width: 768px) {
    
    .footer_btns {
        gap:5px;
    }
    #TelBtn{
        display: block;
        padding: 5px;
        background-color: #fff;
        border-radius: 0; 
        width: 45px;
        height: 45px;
        text-align: center;
        color: rgb(128, 128, 128);
    }
    #TelBtn .MuiSvgIcon-root{
        font-size: 25px;
    }
    #TelBtn svg{
        margin-bottom: 0px;
        margin-top: 5px;
        color: rgb(128, 128, 128);
    }
}

#LineBtn{
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
}
#LineBtn .css-htszrh-MuiButton-startIcon svg{
    overflow: hidden;
    vertical-align: super;
}
#LineBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 30px;
}
#RaitenBtn{
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
}
#RaitenBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 32px;
}
@media screen and (max-width: 768px) {
    #LineBtn{
        width: calc(50% - 45px);
        border-radius: 0;
        font-size: 14px;
        line-height: 1.2;
        height: 45px;
    }
    #LineBtn .css-htszrh-MuiButton-startIcon svg{
        overflow: hidden;
        vertical-align: super;
    }
    #LineBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
        font-size: 25px;
    }
    #RaitenBtn{
        width: calc(50% - 45px);
        text-align: left;
        border-radius: 0;
        font-size: 14px;
        line-height: 1.2;
        height: 45px;
    }
    #RaitenBtn .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
        font-size: 27px;
    }
}


/* ポップアップcss */
@media screen and (max-width: 768px) {
    #popupTel .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        width:100%;
        margin:20px
    }
    #popupTel .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
        padding: 20px;
    }
    #popupTel .shopName{
        font-size: 16px;
        font-weight: bold;
    }
    #popupTel .infos{
        display: flex;
        align-items: baseline;
        font-size: 14px;
        gap: 10px;
        margin-bottom: 10px;
    }
    #popupTel .infos.gai{
        margin-bottom: 5px;
    }
    #popupTel .infos dt{
        padding: 3px;
        border: 1px solid #4ac0f0;
        color: #4ac0f0;
        width: 75px;
        text-align: center;
    }
    #popupTel .infos dd{
        text-align: left;
        width: calc(100% - 85px);
        margin-bottom: 0;
    }
    #popupTel .eigyougai{
        font-size: 14px;
        font-weight: 500;
        color: #E9573F;
    }

    #popupTel .popupBtns{
        margin: 30px 0 20px;
    }
    #popupTel .popupBtns .pb_Tel{
        width: 100%;
    }
    #popupTel .popupBtns .pb_Tel div{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 10px;
        gap: 10px;
        margin-bottom: 10px;
    }
    #popupTel .popupBtns .pb_Tel div .text{
        font-size: 16px;
        font-weight: bold;
    }

    #popupTel .popupBtns .pb_Cancel{
        width: 100%;
        font-size: 16px;
        padding: 15px 10px;
        font-weight: bold;
        border: 1px solid #000;
        color: #000;
        text-align: center;
    }
}
/* #footer-menublock ul.footer-menu_list {
    height: 45px;
    border-top: 1px solid #c8c7cc;
    display: flex;
}
.pa-left-0 {
    padding-left: 0px !important;
}
ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
}
#footer-menublock ul.footer-menu_list li {
    flex: auto;
    float: left;
    list-style: none;
    position: relative;
    text-align: center;
    padding-top: 5px;
}
#footer-menublock ul.footer-menu_list li a.current {
    color: #3BAFDA;
}
#footer-menublock ul.footer-menu_list li a {
    position: relative;
    display: inline-block;
    color: #979797;
}
#footer-menublock ul.footer-menu_list li i {
    display: block;
    font-size: 30px;
}
#footer-menublock a:hover{
    text-decoration: none;
} */