.ContactBtns{
    display: flex;
    width: 80%;
    justify-content: center;
    margin: 30px auto;
    gap: 5px;
}

.ContactBtns .Tel{
    display: none;
}
.ContactBtns .DetailBtns{
    flex-basis: 33%;
    border-radius: 5px;
    height: 80px;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.ContactBtns .DetailBtns .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 38px;
}
.ContactBtns .Line .css-htszrh-MuiButton-startIcon svg {
    overflow: hidden;
    vertical-align: super;
}
.ContactBtns .Mail:hover{
    color: #fff;
}
  

@media screen and (max-width: 768px) {
    .ContactBtns{
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        justify-content: center;
        margin: 20px auto;
        gap: 5px;
    }
    .ContactBtns .Tel{
        display: flex;
    }
    .ContactBtns .DetailBtns{
        flex-basis: 100%;
        border-radius: 5px;
        height: 55px;
        width: 100%;
        border-radius: 0;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
    }
    
    .ContactBtns .DetailBtns .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
        font-size: 25px;
    }
    .ContactBtns .Line .css-htszrh-MuiButton-startIcon svg {
        overflow: hidden;
        vertical-align: super;
    }
}