#DetailComment .recommended_title{
    padding: 50px 0;
    position: relative;
}

#DetailComment .staffImg{
    position: absolute;
    border:2px solid #EBA855;
    top: 10px;
    left: 12%;
    width: 120px;
}
#DetailComment .staffImg img{
    aspect-ratio: 3 / 4;
    /* object-fit:cover; */
    object-fit:contain;
    width: 100%;
    height: auto;
    background: #fff;
}

#DetailComment .recommended_title .title{
    background-color: #EBA855;
    display:flex;
    flex-flow: column;
    font-weight: bold;
    text-align: left;
    color: #fff;
    padding: 10px;
    padding-left: 24%;
}
#DetailComment .recommended_title .title img{
    width: 100px;
}
#DetailComment .recommended_title .title .text{
    font-size: 22px;
    font-weight: 500;
}
#DetailComment .recommended_text{
    font-size: 16px;
    text-align: left;
    padding: 20px 10%;
    line-height: 1.6;
}

@media screen and (max-width: 1200px) {
    #DetailComment .recommended_title{
        padding: 50px 0;
        position: relative;
    }
    
    #DetailComment .staffImg{
        position: absolute;
        border:2px solid #EBA855;
        top: 10px;
        left: 100px;
        width: 120px;
    }
    #DetailComment .staffImg img{
        width: 100%;
    }
    
    #DetailComment .recommended_title .title{
        background-color: #EBA855;
        display:flex;
        flex-flow: column;
        font-weight: bold;
        text-align: left;
        color: #fff;
        padding: 10px;
        padding-left: 230px;
    }
    #DetailComment .recommended_title .title .text{
        font-size: 22px;
        font-weight: 500;
    }
    #DetailComment .recommended_text{
        font-size: 16px;
        text-align: left;
        padding: 20px 100px;
        line-height: 1.6;
    }
}

@media screen and (max-width: 768px) {
    #DetailComment .recommended_title{
        padding: 30px 0;
        position: relative;
    }
    
    #DetailComment .staffImg{
        position: absolute;
        border:2px solid #EBA855;
        top: 10px;
        left: 10px;
        width: 80px;
    }
    #DetailComment .staffImg img{
        width: 100%;
    }
    
    #DetailComment .recommended_title .title{
        background-color: #EBA855;
        display:flex;
        flex-flow: column;
        font-weight: bold;
        text-align: left;
        color: #fff;
        padding: 10px;
        padding-left: 100px;
    }
    #DetailComment .recommended_title .title img{
        width: 80px;
    }
    #DetailComment .recommended_title .title .text{
        font-size: 16px;
    }
    #DetailComment .recommended_text{
        font-size: 14px;
        text-align: left;
        padding: 20px 20px;
        line-height: 1.5;
    }
}
  