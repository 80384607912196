.image_space{
  height: 400px;
  background-color: white;
}
.image_space img{
  height: 300px;
  max-width: 90%;
  /* margin-top: 10px; */
  margin-right: auto;
  margin-left: auto;
  /* margin-bottom: 80px; */
}
.image-gallery-slide-wrapper {
  height: 300px;
}


#DatailImages .image_space{
  /* height: 400px; */
  height: 550px;
  background-color: white;
}
#DatailImages .image-gallery-slides img{
  /* height: 300px; */
  height: 420px;
  max-width: 90%;
  /* margin-top: 10px; */
  margin-right: auto;
  margin-left: auto;
  /* margin-bottom: 80px; */
}
#DatailImages .image_space button{
  /* background-color: black; */
  margin-right: 20px;
  margin-left: 20px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
#DatailImages .image-gallery-slide-wrapper {
  /* height: 300px; */
  height: 420px;
}
#DatailImages .image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: initial !important;
  height: 50px;
  margin-top: 10px;
}
#DatailImages .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 70px;
}
#DatailImages .image-gallery-left-nav .image-gallery-svg,
#DatailImages .image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  /* width: 30px !important; */
}
/* .galleryimg{
  height:500px
} */

@media screen and (max-width: 480px) {
  #DatailImages .image_space{
    height: 300px;
    background-color: white;
  }
  #DatailImages .image_space img{
    height: 200px;
    max-width: 100%;
  }
  .image_space button{
    margin-right: 20px;
    margin-left: 20px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  .image-gallery-slide-wrapper {
    height: 300px;
  }
  #DatailImages .image-gallery-slide-wrapper {
    height: 200px;
  }
  .image-gallery-thumbnails{
    padding: 5px 0;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: initial !important;
    height: 40px;
    margin-top: 5px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 50px;
  }
  #DatailImages .image-gallery-left-nav .image-gallery-svg,
  #DatailImages .image-gallery-right-nav .image-gallery-svg {
    height: 60px !important;
    /* width: 30px !important; */
  }

  #DatailImages .image-gallery-icon.image-gallery-left-nav,
  #DatailImages .image-gallery-icon.image-gallery-right-nav{
    margin: 0 !important;
    padding: 0 !important;
  }
}