.frame_style{
    width: 50%;
    background-color: white;
}
.title_bold{
    font-weight: bold;
}
@media screen and (max-width: 960px) {
    .frame_style{
        width: 100%;
        background-color: white;
    }    
}