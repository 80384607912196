#header-menublock {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #c8c7cc;
    background-color: white;
    background-color: rgba(255,255,255,0.9);
    z-index: 900;
    display: flex;
}  
.header-menu_list{
    height: 50px;
}
.header-menu_list img{
    margin-top: 10px;
    margin-left: 100px;
    width: 150px;
}

.headbtns{
    margin-right: 100px;
    margin-left: auto;
}
#Btns{
    width: 90px;
}
#Btns .icons{
    font-size: 30px;
}

.MuiBottomNavigationAction-label{
    font-family:"Noto Sans JP",sans-serif;
}

@media screen and (max-width: 768px) {
    #header-menublock {
        align-items: center;
        padding: 0 20px;
    }
    .header-menu_list img{
        margin-top: 10px;
        margin-left: 0px;
        width: 130px;
    }

    .headbtns{
        margin-right: 0px;
        margin-left: auto;
    }
    #Btns{
        width: 50px !important;
        padding-right: 0 ;
    }
    #Btns .MuiBottomNavigationAction-label{
        font-size: 12px;
    }
    #Btns .icons{
        font-size: 20px;
    }
}
