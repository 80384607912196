#Contact{
    margin: 50px 0;
}
#Contact .inner{
    padding: 30px 10%;
}
#Contact h3{
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #FF9429;
}
#Contact .form_box{
    padding: 30px 0;
}
#Contact .form_box .title{
    font-size: 16px;
    padding-right: 20px;
}
#Contact .form_box .required{
    font-size: 14px;
    padding: 2px 5px;
    color: #fff;
    border-radius: 5px;
    background-color: #F08080;
}

#Contact .form_box .selectbox{
    padding-top: 20px;
}

#Contact .form_box .box{
    padding-top: 30px;
}

#Contact .mailbox{
    padding-top: 20px;
}
#Contact .raitenyoyaku{
    display: flex;
    padding-top: 20px;
    gap: 20px;
}
#Contact .timebox{
    width: 200px;
}
#Contact .shitsumon{
    display: block;
    width: 100%;
    margin-top: 20px;
    border-color: #cfcfcf;
    border-radius: 5px;
    height:200px;
}
#Contact textarea.shitsumon:focus {
    border:2px solid #007aff;
    outline: none;
}
#Contact .btnbox{
    text-align: center;
}
#Contact .LoadingButton{
    font-size: 18px;
    padding: 15px;
    width:200px;
    font-family:"Noto Sans JP",sans-serif;
    border-radius: 0;
}
#Contact .soushin[type="button"]:disabled{
    background-color: #e8e8e8;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    width:200px;
    font-family:"Noto Sans JP",sans-serif;
    border-radius: 0;
}
#Contact .soushin[type="button"]:not(:disabled){
    background-color: #EBA855;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    width:200px;
    font-family:"Noto Sans JP",sans-serif;
    border-radius: 0;
}
#Contact #RaitenBtn{
    margin-left: 10px;
    padding: 15px;
    width:200px;
}
@media screen and (max-width: 768px) {
    #Contact{
        margin: 20px 0;
    }
    #Contact .inner{
        padding: 30px 20px;
    }
    #Contact h3{
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom: 2px solid #FF9429;
    }
    #Contact .form_box{
        padding: 10px 0;
    }
    #Contact .form_box .title{
        font-size: 15px;
        padding-right: 20px;
    }
    #Contact .form_box .required{
        font-size: 13px;
        padding: 2px 5px;
        color: #fff;
        border-radius: 5px;
        background-color: #F08080;
    }

    #Contact .form_box .selectbox{
        padding-top: 10px;
    }
    
    #Contact .form_box .box{
        padding-top: 20px;
    }
    
    #Contact .raitenyoyaku{
        display: flex;
        padding-top: 10px;
        gap: 10px;
    }
    #Contact .timebox{
        width: 150px;
    }
    #Contact .shitsumon{
        margin-top: 10px;
    }
    #Contact .btnbox{
        text-align: center;
    }
    #Contact .LoadingButton{
        font-size: 15px;
        padding: 10px;
    }
    #Contact .soushin{
        background-color: #EBA855;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        width:200px;
        font-family:"Noto Sans JP",sans-serif;
        border-radius: 0;
    }
    #Contact #RaitenBtn{
        margin-top: 10px;
        margin-left: 0px;
        padding: 15px;
        height: 60px;
        font-size: 16px;
        width:200px;
    }
}