.map-contents .image_space{
  border: solid 1px lightgray;
  border-radius: 5px;
  height: 200px;
  background-color: white;
  margin-bottom: 20px;
}
.map-contents .image_space img{
  max-height: 200px;
  max-width: 90%;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 80px;
}
.map-contents .image-gallery-slide img {
  width: 100%;
  height: 170px;
}
.map-contents {
  height:180px;
}
.map-contents .image-gallery-left-nav, .map-contents .image-gallery-right-nav {
  top: 30%;

}
.map-contents .image_space button{
  /* background-color: black; */
  margin-right: 10px;
  margin-left: 10px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.image-gallery-slide-wrapper {
  height: 100px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {

  height: 50px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {

  width: initial !important;

}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 80px;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  /* width: 30px !important; */
}
/* .galleryimg{
  height:500px
} */