.confirmation_container {
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
    padding-bottom: 70px;
}
.h1_style {
    font-size: 18px;
    padding: 10px;
    text-align: center;
    background-color: lightgray;
    border: solid 1px;
}
.h2_style {
    font-size: 16px;
    text-align: center;
}
/* .grid_container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
} */
.sentence_container p {
    font-size: 14px;
    line-height: 1.2em;
    margin-bottom: 0;
}
.sentence_container p.indent1 {
    padding-left: 1em;
    text-indent: -1em;
}
.sentence_container p.indent2 {
    padding-left: 2em;
    text-indent: -1em;
}
.sentence_container p.indent3 {
    padding-left: 3em;
    text-indent: -1em;
}
.sentence_container p.indent4 {
    padding-left: 4em;
    text-indent: -1em;
}
.sentence_container p.indent5 {
    padding-left: 2em;
    text-indent: -2em;
}
.h3_style {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
}
.consent_btn {
    text-align: center;
    margin-top: 30px;
}
@media screen and (max-width: 960px) {
    .confirmation_container {
        width: 90%;
        padding-bottom: 50px;
    }
}