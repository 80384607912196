input[type="checkbox"] {
    transform: scale(1.5);
}

label {
    display:block;
    position:relative;
}

/* .conditions_style{
    margin-right: auto;
    margin-left: auto;
    background-color: white;
} */
.display_style{
    background-color: whitesmoke;
    padding-top: 70px;
    padding-bottom: 30px;
}
.text_center, .text_center2, .text_center3, .text_center4{
    margin: auto;
}
.text_center, .text_center2, .text_center3{
    font-size: 15px;
    font-weight: bold;
}
.text_center2{
    font-size: 22px;
    color: #00B900;
    background-color: #ffffff;
    padding: 5px 20px;
}
.text_center3{
    color: #00B900;
}
.list1{
    float: left;
}
.info_style{
    font-size: 16px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 5px;
    background-color: #e0f7d9;
}
.line-info ul{
    vertical-align: right;
    list-style-type: none;
}
.line-step{
    border-top: 4px white solid;
}
.line-step dt, dd{
    text-align: center;
}
.line-step dt{
    font-size: 14px;
}
.text_style1{
    font-size: 14px;
    margin-left: 15px;
}
.text_style2, .text_style3{
    font-size: 14px;
    text-align: center;
}
.text_style3{
    font-size: 46px;
}
.text_style4{
    font-size: 12px;
}
.text_style5{
    font-size: 18px;
    font-weight: bold;
    position: relative;
    left: 27px;
    top: -25px;
}
.line_middle img{
    width: 35%;
    float: right;
}
.pct{
    margin-top: 20px;
    min-height: 130px;
}
.btn_style1{
    font-size: 20px;
    font-family: sans-serif;
    color: #ffffff;
    background-color: #00B900;
    border-color: #00B900;
    border: 1px solid #00B900;
    border-radius: 4px;
    width: 220px;
    letter-spacing: 5px;
    margin-top: 5px;
    padding-top: 5px;
}
.code_color{
    color: #8f315d;
    background-color: #f7d9f0;
    border-radius: 4px;
}
.frame{
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #434a54;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}
/* #footer-submit_button{
    position: fixed;
    height: auto;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 60px;
    left: 0;
    width: 100%;
    border-top: 1px solid #c8c7cc;
    background-color: white;
    background-color: rgba(255,255,255,0.9);
    z-index: 900;
} */
.MuiIconButton-label{
    background-color: white;
}
.station_style{
    writing-mode: vertical-rl;
    width: 50px;
    height: 173px;
}
.bottom_style{
    text-align: center;
    display: inline-block;
    vertical-align: top;
}
@media screen and (max-width: 960px) {
    .line_middle img{
        width: 100%;
    }
    .text_center2{
        font-size: 15px;
        text-align: center;
        padding: 5px 15px;
    }
    .text_style5{
        font-size: 12.5px;
        top: -21px;
    }
    .qrCode_none{
        display: none;
    }
}