#staffbox {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 20px;
    font-family:"Noto Sans JP",sans-serif;
    /* justify-content: center; */
}
@media screen and (max-width: 768px) {
    #staffbox {
        display: flex;
        flex-flow: column;
        padding: 20px 0;
        gap: 20px;
    }
}

#staffbox .staff_coment,
#staffbox .staff_profile{
    background-color: #FFFFFF;
}

#staffbox .staff_coment{
    width: 100%;
    /* width: calc(55% - 20px); */
    padding:30px;
    background-color: #FFFFFF;
}

#staffbox .staff_coment .coment{
    font-size: 16px;
    max-height: 300px;
    overflow: scroll;
    overflow-y: scroll;
}

#staffbox .staff_coment .coment::-webkit-scrollbar{
    width: 10px;
 }
 #staffbox .staff_coment .coment::-webkit-scrollbar-track{
    background-color: #FFFFFF;
}
#staffbox .staff_coment .coment::-webkit-scrollbar-thumb{
    background-color: #707070;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {

    #staffbox .staff_coment,
    #staffbox .staff_profile{
        background-color: #FFFFFF;
    }
    
    #staffbox .staff_coment{
        width: 100%;
        /* padding:30px 30px; */
        padding:20px;
        background-color: #FFFFFF;
    }
    
    #staffbox .staff_coment .coment{
        font-size: 16px;
        max-height: 300px;
        overflow: scroll;
        overflow-y: scroll;
    }
    
    #staffbox .staff_coment .coment::-webkit-scrollbar{
        width: 10px;
     }
     #staffbox .staff_coment .coment::-webkit-scrollbar-track{
        background-color: #FFFFFF;
    }
    #staffbox .staff_coment .coment::-webkit-scrollbar-thumb{
        background-color: #707070;
        border-radius: 5px;
    }
}


#staffbox .staff_profile{
    /* width: 45%; */
    width: 100%;
    /* height: 360px; */
    border:double 10px #4ac0f0; 
}

.titleboxs{
    margin: -10px auto 0;
    padding: 5px 0px ;
    background-color: #4ac0f0;
    width: 200px;
    text-align: center;
}
.profile{
    width: 60px;
}
.tantou .MuiSvgIcon-root{
    font-size: 30px;
    color: #fff;
}
.tantou span{
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.profile_box{
    display: flex;
    padding: 20px 50px 40px;
}

.profile_box .profiles{
    flex-basis: 40%;
    width: 100%;
}

.profile_box .profiles2{
    flex-basis: 60%;
    width: 100%;
}

.profile_box .profile_comment{
    font-size:16px;
}

.profile_inner{
    display: flex;
    /* padding:  20px 30px; */
    gap: 20px;
}

.profile_inner .face img{
    border: 3px solid #4ac0f0;
    max-width: 200px;
}

.profile_inner .names{
    margin: 10px 0 ;
    border-left: 3px solid #4ac0f0;
    padding-left: 10px;
}

.profile_inner .names .tenpo{
    font-size: 12px;
    margin-bottom: 0px;
}
.profile_inner .names .name{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}
.profile_inner .names .kana{
    font-size: 12px;
    margin-bottom: 0px;
    letter-spacing: 1px;
}

.profile_inner .tokuthou{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}
.profile_inner .tokuthou p{
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 5px ;
}
.profile_inner .tokuthou .t1{
    background-color: #bf4645;
}
.profile_inner .tokuthou .t2{
    background-color: #51bcbc;
}
.profile_inner .tokuthou .t3{
    background-color: #eba855;
}
.profile_inner .tokuthou .t4{
    background-color: #995591;
}
.profile_inner .tokuthou .t5{
    background-color: #da6982;
}
.profile_inner .tokuthou .t6{
    background-color: #957054;
}

@media screen and (max-width: 768px) {
    #staffbox .staff_profile{
        width: 100%;
        height: auto;
        border:double 10px #4ac0f0; 
    }
    
    .titleboxs{
        margin: -10px auto 0;
        padding: 5px 0px ;
        background-color: #4ac0f0;
        width: 200px;
        text-align: center;
    }
    .profile{
        width: 60px;
    }
    .tantou .MuiSvgIcon-root{
        font-size: 30px;
        color: #fff;
    }
    .tantou span{
        padding-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .profile_box{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 40px;
    }
    
    .profile_box .profiles{
        flex-basis: 100%;
        width: 100%;
    }
    
    .profile_box .profiles2{
        flex-basis: 100%;
        width: 100%;
    }
    
    .profile_box .profile_comment{
        font-size:16px;
    }
    
    .profile_inner{
        display: flex;
        /* padding:  15px 20px; */
        padding-bottom: 20px;
        gap: 10px;
    }
    
    .profile_inner .face img{
        border: 3px solid #4ac0f0;
        width: 100px;
        max-width: 100px;
    }
    
    .profile_inner .names{
        margin: 10px 0 ;
        border-left: 3px solid #4ac0f0;
        padding-left: 10px;
    }
    
    .profile_inner .names .tenpo{
        font-size: 12px;
        margin-bottom: 0px;
    }
    .profile_inner .names .name{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
    }
    .profile_inner .names .kana{
        font-size: 12px;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
    
    .profile_inner .tokuthou{
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }
    .profile_inner .tokuthou p{
        font-size: 12px;
        margin: 0px;
        padding: 5px ;
    }
    
}

.tokugishumi{
    padding-bottom:  10px;
}
.tokugishumi dl{
    display: flex;
    font-size: 16px;
    margin-bottom: 5px;
}
.tokugishumi dt{
    width: 70px;
    color: #4ac0f0;
}
.tokugishumi dd{
    text-align: left;
    width: calc(100% - 70px);
}

@media screen and (max-width: 768px) {

}