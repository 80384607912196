
.container .jumbotron{
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    padding: 0 20px 20px 20px;
    margin: 10px 10px 0 10px;
}
.btn.btn-shadow.btn-cliant-white {
    background: white;
    border: 1px solid #AAB2BD;
    -webkit-box-shadow: 1rgba 235, 235, 235, 0.7 0 -4px 0 inset;
    box-shadow: rgba(235,235,235,0.7) 0 -4px 0 inset;
    color: #434A54;
    font-weight: bold;
}
.favorite_btn {
    width: 20%;
    margin-top: 20px;
}
.table_layout{
    font-size: 15px;
    border: none;
}
.table_layout th{
    width: 30%;    
    background-color: white;
}
.table_layout td, .table_layout th{
    border:none;
    padding: 0.1em;
    height: auto;
}
.table_layout tr{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.contain {
    display: inline-block;
    /* background-color: #252525; */
    background-position: center center;
    background-repeat: no-repeat;
  
    width: 150px;
    height: 150px;
    background-size: contain;
}

/* .btn.btn-shadow.btn-cliant-blue {
    background: #347bd8;
    border: 1px solid #347bd8;
    border-bottom: 4px solid #2362b7;
} */
/* .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} */
/* .btn {
    color: #FFF;
} */
/* .btn-block {
    display: block;
    width: 100%;
} */
/* .btn.btn-shadow.btn-cliant-blue span.small {
    font-size: 16px !important;
    padding-top: 2px;
    padding-bottom: 2px;
} */
/* .btn:hover, .btn:focus, .btn:active, .btn.active {
    color: #FFF;
    border-color: #CCD1D9;
    background-color: #CCD1D9;
    outline: none !important;
} */
.color-red {
    color: #E9573F;
}
strong.lead {
    font-size: 20px;
    font-weight: bold;
}
.jumbotron-contents{
    position: relative;
    text-align: center;
    padding-top: 10px;
}
.jumbotron-contents img{
    max-width: 315px;
    height: 250px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
}
.jumbotron-contents p{
    position: absolute;/*絶対配置*/
    color: gray;/*文字色*/
    font-weight: bold;
    font-size: 2em;/*サイズ2倍*/
    height: 70px;
    width: 320px;
    background: rgba(255, 255, 255, 0.7);/*背景を半透明に*/
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
}
.ma-bottom-8 {
    margin-top: 20px;
    margin-right: 10px;
}
.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.label-info, .badge-info {
    border-color: #37BC9B;
    background-color: #37BC9B;
}
@media screen and (max-width:768px) {
    .table_layout{
        font-size: 14px;
    }
    strong.lead {
        font-size: 18px;
    }
}