.TatemonoDetail{
    display: flex;
    padding-top: 20px;
}

.gaikan{
    max-width:120px;
    height:80px
}

#Tatemono .tables{
    display: flex;
    width: calc(100% - 120px);
    font-size: 14px;
}

#Tatemono .tables .tab{
    display: flex;
    width: calc(100% - 120px);
    font-size: 14px;
    flex-flow: column ;
    margin-left: 10px;
}

#Tatemono .tables .tab1{
    flex-basis: 30%;
}
#Tatemono .tables .tab2,
#Tatemono .tables .tab3,
#Tatemono .tables .tab5{
    flex-basis: 20%;
}
#Tatemono .tables .tab4{
    flex-basis: 10%;
}

#Tatemono .tables .th,
#Tatemono .tables .td{
    padding: 8px 10px;
}
#Tatemono .tables .th{
    width: auto;
    letter-spacing: inherit;
    height: 35px;
    background-color: #f2f4f8;
}

@media screen and (max-width: 768px) {

    
    .TatemonoDetail{
        display: flex;
        padding-top: 20px;
    }
    
    .gaikan{
        max-width:100px;
        height:100%;
    }
    
    #Tatemono .tables{
        display: flex;
        width: calc(100% - 80px);
        font-size: 14px;
        flex-flow: column ;
        gap: 3px;
    }
    
    #Tatemono .tables .tab{
        display: flex;
        width: 100%;
        font-size: 12px;
        flex-flow: nowrap ;
        margin-left: 10px;
    }
    
    #Tatemono .tables .tab1
    #Tatemono .tables .tab2,
    #Tatemono .tables .tab3,
    #Tatemono .tables .tab4,
    #Tatemono .tables .tab5{
        flex-basis: 100%;
    }
    
    #Tatemono .tables .th,
    #Tatemono .tables .td{
        padding: 5px;
    }
    #Tatemono .tables .th{
        width: 50px;
        text-align: center;
        letter-spacing: inherit;
        height: auto;
        background-color: #f2f4f8;
    }

}
