#Location .map_space{
  /* padding: 10px 10px 10px 10px; */
  padding: 0;
}
.location_style th, td{
  padding: 10px 10px 10px 10px;
}
.location_style table{
  margin-top: 10px;
}



#Location #MapBtns{
  width: 80%;
  margin: 0px auto 0px;
}
#MapBtns .mapbtns{
  padding: 5px;
  width: 12.5%;
  /* width: 100%; */
}

#MapBtns .mapbtns img{
  width: 100%;
}
#Location .shuhen{
  font-family:"Noto Sans JP",sans-serif;
  width: 100%;
  padding-top: 10px;
  font-size: 15px;
}

@media screen and (max-width: 1200px) {
  #Location #MapBtns{
    width: 100%;
    margin: 0px auto 0px;
  }
}

@media screen and (max-width: 768px) {

  #MapSwiper{
      padding: 10px 15px;
      width: 100%;
      /* margin: 0 -15px 0; */
  }
  #MapSwiper .mapbtns{
      padding: 0px;
      width: 100%;
  }
  #MapSwiper .mapbtns img{
      width: 100%;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
      display: none;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
      display: none;
  }
  #Location .shuhen{
      width: 100%;
      padding-left: 0px;
      padding-top: 0px;
      font-size: 14px;
  }
}


#SortBox{
  display: block;
  margin: 20px 10px 0 auto;
}
#Sort{
  font-family:"Noto Sans JP",sans-serif;
  width: 150px;
  padding: 15px;
  border: 1px solid #999999;
  border-radius: 5px;
  background-color: #fff;
}

#SortBox .MuiInput-underline:before {
  display: none !important;
}
#SortBox .MuiInput-underline:after{
  display: none !important;
}

#Sort .MuiMenuItem-root{
  font-family:"Noto Sans JP",sans-serif;
}

@media screen and (max-width: 768px) {
  #SortBox{
      display: block;
      margin: 10px 10px 0 auto;
      font-size: 14px;
  }
  #Sort{
      font-family:"Noto Sans JP",sans-serif;
      width: 120px;
      padding: 10px;
      border: 1px solid #999999;
      border-radius: 5px;
      background-color: #fff;
      font-size: 14px;
  }
  
  #SortBox .MuiInput-underline:before {
      display: none !important;
  }
  #SortBox .MuiInput-underline:after{
      display: none !important;
  }
  
  #Sort .MuiMenuItem-root{
      font-size: 14px;
      font-family:"Noto Sans JP",sans-serif;
  }
  .table_style{
      display: block;
  }
}