
.rightBox{
  height: 550px;
}

.floorPlan_space{
  /* height: 400px; */
  background-color: white;
  text-align: center;
}
.floorPlan_space th{
  text-align: left;
  padding: 10px;
}

.floorPlan_space img{
  max-height: 260px;
  max-width: 400px;
  margin-top: 10px;
}

@media screen and (max-width: 480px) {
  .rightBox{
    height: auto;
    margin-bottom: 30px;
  }
  
  .floorPlan_space img{
    max-height: 250px;
    max-width: 300px;
  }  
}
.floorPlan_space{
  padding: 10px;
}
.floorPlan_space .item1,.floorPlan_space .item3{
  padding-right: 5px ;
}
.floorPlan_space .item1{
  margin-bottom: 10px;
}
.floorPlan_space .item2,.floorPlan_space .item4{
  padding-left: 5px ;
}
.floorPlan_space .item4 a:hover{
  color: #fff;
}
.DetailBtns{
  border-radius: 5px;
  height: 80px;
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.DetailBtns .css-htszrh-MuiButton-startIcon>*:nth-of-type(1){
  font-size: 38px;
}

.Line .css-htszrh-MuiButton-startIcon svg {
  overflow: hidden;
  vertical-align: super;
}

.rightBox .floorPlan_space.madori{
  height: 325px;
}

@media screen and (max-width: 480px) {
  .rightBox .floorPlan_space.madori{
    height: 260px;
  }
}