
.recommended_style th, td{
  font-size: 15px;
  /* padding: 10px; */
}
.recommended_point{
  font-size: 16px;
  border-left: 5px solid #4AC0F0;
  padding-left: 10px;
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .recommended_style th, td{
    font-size: 14px;
  }
  .facility_table td{
    font-size: 12px;
    padding: 5px;
  }
  .facility_table th{
    font-size: 12.5px;
    padding: 5px;
  }
}