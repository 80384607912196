input[type="checkbox"] {
    transform: scale(1.5);
}

label {
    display:block;
    position:relative;
}

.conditions_style{
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    margin-bottom: 150px;
}
.container h6{
    font-weight: bold;
}
.display_style{
    /* background-color: whitesmoke; */
    padding-top: 50px;
    padding-bottom: 125px;
}
.title_style{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
}
/* .monthly-h1 {
    font-size: 19px;
    border: solid 2px;
    padding: 0.5em;
    border-radius: 0.5em;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
} */

/* .pg-hdr {
    background: url(../Images/bg-slash-green.png) left top repeat;
    color: #ffffff;
    padding: 15px;
    text-align: center;
} */
.Comming #footer-submit_button{
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    /* padding-bottom: 60px; */
    padding-bottom: 0px;
    left: 0;
    width: 100%;
    border-top: 1px solid #c8c7cc;
    background-color: white;
    background-color: rgba(255,255,255,0.9);
    z-index: 900;
}
.MuiIconButton-label{
    background-color: white;
}
.station_style{
    writing-mode: vertical-rl;
    width: 50px;
    height: 173px;
}
@media screen and (max-width: 960px) {
    .conditions_style{
        width: 100%;
    }        
}