.RaitenS{
    display:flex;
    align-items:center;
    margin-bottom:5px;
    padding-bottom:5px;
    border-bottom: 1px solid #cccccc;
}
.RaitenS.last{
    border-bottom: none;
}

.reserve-table{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.reserve-table th{
    border: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    text-align: center;
    font-weight: inherit;
    padding: 0;
    height: 100%;
}
.reserve-table td{
    width: 12%;
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 100%;
}
.reserve-table .tr-date{
    border-bottom: 2px solid #dddddd;
}
.reserve-table .reserve_cell{
    height: 40px;
    padding-top: 7px;
}
.reserve-table .mouse_over:hover {
	background-color: #fff0f5;
}
.dateCell_sat {
    color: #08ade7;
}
.dateCell_sun {
    color: #ff7153;
}

table.timeTable{
    border-collapse: collapse;
    margin-right: auto;
    margin-left: auto;
    width: auto;
}
table.timeTable th, table.timeTable td {
    border:1px solid #BFB9B0;
    /*実線 1px 黒*/
    background-color: #ffffff;
    padding: 0px;
    color: #333333;
}

.table_slide {
    width: 100%;
    margin: 15px;
    overflow-x: scroll;
}
.reserve_style{
    margin-top: 50px;
    padding-bottom: 30px;
    background-color: white;
}
.reserve_style .today_btn{
    font-size: 13px;
    padding: 3px 8px;
}
.reserve_style .month_week{
    font-size: 13px;
    padding: 3px 8px;
    margin-left: 5px;
}
.month-cell{
    text-align: center;
}

table.timeTable .time-cell{
    text-align: right;
    padding-right: 10px;
    background-color: #f6f6f6;
}
table.timeTable .time-cell-l{
    text-align: left;
    padding-left: 10px;
    background-color: #f6f6f6;
}
.np-cell{
    text-align: center;
    font-size: 12px;
    width:86px
}
.border1{
    border-top: 2px solid #dddddd;
}

.reserve_info{
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.reserve_info button{
    margin-top: 10px;
}
.react-calendar__month-view__days__day{
    height:50px;
}
.react-calendar{
    width:100%  !important;
    border:0px !important;
    /* -webkit-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -moz-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    -ms-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
    filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2)); */
}
.react-calendar__month-view__days__day--weekend{

    /* color:#000 !important; */
}
/* .react-calendar__month-view__weekdays__weekday{
    color:red !important;
} */
.yoyaku_title{
    background-color: white;
    width: 30%;
}
.confirm_btn_style{
    margin-top: 50px;
    text-align: center;
}
.table_style{
    margin-top: 0;
    background-color: white;
}
.label_info{
    border-color: lightcoral;
    background-color: lightcoral;
    padding: .2em .6em .3em;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    color: white;
    border-radius: .25em;
}
.height_style{
    padding-top: 45px;
    margin-top: 15px;
    height: 730px;
    text-align: center;
}
.reserve_height{
    /* height: 730px; */
    height: auto;
}
.item_style {
    font-size: 15px;
    font-weight: bold;
}
.done_style {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.Titles{
    font-size:20px;
    padding-top:20px;
}


@media screen and (max-width: 768px) {
    .Titles{
        font-size:18px;
        padding-top:20px;
    }
    .RaitenS{
        display:flex;
        flex-flow: column;
        align-items:baseline;
        padding-bottom:10px;
    }
    #Change .table_style{
        display: block !important;
    }
}
@media screen and (max-width: 480px) {
    

    .height_style{
        height: 460px;
    }

    .reserve-table{
        margin-top: 10px;
        font-size: 10px;
        width: 315px;
        margin-left: auto;
        margin-right: auto;
    }
    .reserve-table .kara{
        display: none;
    }
    .table_slide{
        overflow-x: unset;
        margin: 0;
    }
    .reserve-table td{
        width: 12%;
    }
    .reserve_style .today_btn{
        min-width: 60px;
        font-size: 12px;
        padding: 3px 0;
    }
    .reserve_style .month_week{
        min-width: 60px;
        font-size: 12px;
        padding: 3px 0;
        margin-left: 2px;
    }
}