table {
    border: 1px solid #dcdcdc;
    width: 100%;
}

th {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: center !important;
    padding: 3px;
}

td {
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: left; 
    padding: 3px;
}

.form-group {
    margin-bottom: 0px;
}

.disp_table th{
    height:28px;
    font-size: 13px;
}
.disp_table td{
    height:28px;
}
