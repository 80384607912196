table {
    border: 1px solid #dcdcdc;
    width: 100%;
    font-family:"Noto Sans JP",sans-serif;
    font-size: 16px;
    /* table-layout: fixed; */
}

th {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: center;
    padding: 3px;
}

td {
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: left;
    padding: 3px;
}

.form-group {
    margin-bottom: 0px;
}

.disp_table th{
    height:28px;
    font-size: 13px;
}
.disp_table td{
    height:28px;
}

@media screen and (max-width: 768px) {
    table {
        font-size: 14px;
    }
}
