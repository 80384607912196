
.wrapper {
    margin: 0px;
    padding: 0px;
      position: relative;
      width: 20px;
      height: 35px;
  }
  
  .tatemono {
    background-color: #000000;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    /* padding-bottom:-2px; */
    border-radius: 15px;
    color: white;
    /* margin: 5px auto; */
    margin-left:10px;
    margin-top:10px;
    padding-top:4px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
}






  .pin {
      background-color: rgb(50, 158, 65);
      width: 20px;
      height: 20px;
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      /* padding-bottom:-2px; */
      border-radius: 10px;
      color: white;
      /* margin: 5px auto; */
      margin-left:10px;
      margin-top:10px;
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
  }
  
  /* .pin:after {
      position: absolute;
      content: '';
      border-top: 15px solid rgb(31, 24, 100);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0px;
      top: 15px;
      left: 15px;
  } */
  
  .pin span {
      line-height: 25px;
      text-align: center;
      vertical-align: central;
      /* vertical-align: middle; */
  }
  .pinline {
      background-color: rgb(252, 142, 79);
      width: 20px;
      height: 20px;
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      /* padding-bottom:-2px; */
      border-radius: 10px;
      color: white;
      /* margin: 5px auto; */
      margin-left:10px;
      margin-top:10px;
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
  }
  
  .pin2 {
      background-color: rgb(11, 184, 175);
      width: 30px;
      height: 30px;
      text-align: center;
      vertical-align: middle;
      padding:4px;
      font-size: 14px;
      /* padding-bottom:-2px; */
      border-radius: 20px;
      color: white;
      /* margin: 5px auto; */
      margin-left:10px;
      margin-top:10px;
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
  }
  .pinComment{
      background-color: rgb(29, 62, 251);
      width: 30px;
      height: 30px;
      text-align: center;
      vertical-align: middle;
      padding:4px;
      font-size: 14px;
      /* padding-bottom:-2px; */
      border-radius: 20px;
      color: white;
      /* margin: 5px auto; */
      margin-left:10px;
      margin-top:10px;
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.6));
  }