.for-large{
    display: block;
}
.for-small{
    display: none;
}

@media screen and (max-width: 768px) {
    .for-large{
        display: none;
    }
    .for-small{
        display: block;
    }
}

.pt-3{
    height: 600px;
    z-index: 5;
    top: 10px;
    left: 10px;
    right: 10px;
    position:relative;
}

@media screen and (max-width: 768px) {
    .pt-3{
        height: 500px;
        z-index: 5px;
        top: 0px;
        left: 0px;
        right: 0px;
        position:relative;
    }
}

#FabBtn{
    position: absolute;
    content: "";
    top: 25px;
    left: 10px;
    background-color: #fff;
    padding: 10px;
}
#FabBtn span{
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    padding-left: 5px;
    color: #000;
}

.rent_layout{
    background-color: whitesmoke;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 130px;
}
.store_color1{
    display: inline-block;
    color: orange;
}
.store_color2{
    display: inline-block;
    color: #37BC9B;
}
.frame_style{
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}


.RentMap #MapBtns{
    /* width: 85%;
    margin: -70px auto 0px; */
    position: absolute;
    width: 90%;
    bottom: 20px;
    left: calc(50% - 46.5%);
    z-index: 9;
}
.RentMap #MapBtns .mapbtns{
    padding: 5px;
    width: 12.5%;
    /* width: 100%; */
}

.RentMap #MapBtns .mapbtns img{
    width: 100%;
}
.shuhen{
    font-family:"Noto Sans JP",sans-serif;
    width: 100%;
    padding-top: 20px;
    font-size: 15px;
}


@media screen and (max-width: 768px) {
    #FabBtn{
        position: absolute;
        content: "";
        top: 25px;
        left: 5px;
        background-color: #fff;
        padding: 5px;
    }
    #FabBtn span{
        font-size: 14px;
        line-height: 1.3;
        text-align: left;
        padding-left: 5px;
        color: #000;
    }
    /* .RentMap #MapBtns{
        position: absolute;
        width: 100%;
        bottom: 20px;
        left: calc(50% - 46.5%);
    } */
    #MapSwiper{
        margin: -70px auto 0px;
        padding: 10px 15px;
        width: 100%;
        /* margin: 0 -15px 0; */
    }
    #MapSwiper .mapbtns{
        padding: 0px;
        width: 100%;
    }
    #MapSwiper .mapbtns img{
        width: 100%;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        display: none;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        display: none;
    }
    .shuhen{
        width: 100%;
        padding-left: 15px;
        padding-top: 0px;
        font-size: 14px;
    }
}


#SortBox{
    display: block;
    margin: 20px 10px 0 auto;
}
#Sort{
    font-family:"Noto Sans JP",sans-serif;
    width: 150px;
    padding: 15px;
    border: 1px solid #999999;
    border-radius: 5px;
    background-color: #fff;
}

#SortBox .MuiInput-underline:before {
    display: none !important;
}
#SortBox .MuiInput-underline:after{
    display: none !important;
}

#Sort .MuiMenuItem-root{
    font-family:"Noto Sans JP",sans-serif;
}

@media screen and (max-width: 768px) {
    #SortBox{
        display: block;
        margin: 10px 10px 0 auto;
        font-size: 14px;
    }
    #Sort{
        font-family:"Noto Sans JP",sans-serif;
        width: 120px;
        padding: 10px;
        border: 1px solid #999999;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
    }
    
    #SortBox .MuiInput-underline:before {
        display: none !important;
    }
    #SortBox .MuiInput-underline:after{
        display: none !important;
    }
    
    #Sort .MuiMenuItem-root{
        font-size: 14px;
        font-family:"Noto Sans JP",sans-serif;
    }
    .table_style{
        display: none;
    }
}


#DispList{
    font-family:"Noto Sans JP",sans-serif;
    position: relative;
    margin: 20px 10px 0;
    width: 100%;
    border: 1px solid #707070;
    padding: 10px 30px;
    font-size: 15px;
    background-color: #fff;
    justify-content:left;
    color: #000;
    font-weight: 600;
}

#DispList .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1){
    font-size: 40px;
    fill: #707070;
}
#DispList #plms::before,#DispList #plms::after {
    position: absolute;
    content: "";
    background-color: #000;
    width: 20px;
    height: 2px;
    right: 20px;
    transition: .2s;
}
#DispList #plms::after{
    transform: rotate(90deg);
}
#DispList #plms.active::after{
    transform: rotate(0deg);
}